import { jsx as _jsx } from "react/jsx-runtime";
import { usePlatformContext } from '../platformContext';
import { useMemo, useEffect, useRef } from 'react';
import { platformLocaleToBcp47 } from '../../utils/platformLocaleToBcp47';
export const PullRequestPlayerEmbedder = (props) => {
    var _a, _b, _c, _d;
    const iframeRef = useRef(null);
    const lang = (_d = (_c = (_b = (_a = usePlatformContext().sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.i18nLanguagePreference) !== null && _d !== void 0 ? _d : null;
    const enhancedUrl = useMemo(() => {
        var _a;
        const url = new URL(props.playerUrl);
        url.searchParams.set('lang', (_a = platformLocaleToBcp47(lang)) !== null && _a !== void 0 ? _a : 'en-US');
        return url.toString();
    }, [props.playerUrl, lang]);
    useInstallWindowMessageListener(iframeRef, 'explore', props.eventHandlers);
    return (_jsx("iframe", { ref: iframeRef, src: enhancedUrl, style: props.style, title: "Code Review Player", allow: "display-capture" // PLAT-13084 / ff07b1b0
     }));
};
const sendAmplitudeInitializationDetailsResponse = (iframeRef, sessionUser, rawAnalyticsService, portalArea) => {
    var _a, _b, _c;
    const iframeWindow = (_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow;
    if (!iframeWindow) {
        console.error('cannot respond to pr analytics initialization request due to no iframe or window');
    }
    else if (!sessionUser) {
        console.error('cannot respond to pr analytics initialization request due to no user');
    }
    else {
        const userId = (_b = window.amplitude) === null || _b === void 0 ? void 0 : _b.getUserId();
        const deviceId = (_c = window.amplitude) === null || _c === void 0 ? void 0 : _c.getDeviceId();
        if (!userId || !deviceId) {
            console.error('cannot respond to pr analytics initialization request due to no userId/deviceId');
        }
        else {
            const details = {
                apiKey: window.SCW_ENV.AMPLITUDE_APIKEY,
                appVersion: window.SCW_ENV.GIT_COMMIT,
                userId,
                deviceId,
                groups: rawAnalyticsService.getAmplitudeGroups(sessionUser),
                eventProps: Object.assign(Object.assign({}, rawAnalyticsService.getRouteProps()), rawAnalyticsService.getExtraEventProps()),
                userProps: rawAnalyticsService.generateUserProps(),
                portalArea,
            };
            iframeWindow.postMessage({ event: 'pr:amplitude initialization details response', details }, '*');
        }
    }
};
const sendUserInfoToIframe = (iframeRef, sessionUser) => {
    var _a, _b, _c, _d;
    const iframeWindow = (_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow;
    if (iframeWindow) {
        const userDetails = {
            firstName: (_d = (_c = (_b = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.first,
            email: sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.email,
        };
        iframeWindow.postMessage({ event: 'pr:user info response', userDetails }, '*');
    }
};
const useInstallWindowMessageListener = (iframeRef, portalArea, eventHandlers) => {
    const { rawAnalyticsService, sessionUser } = usePlatformContext();
    const { onEndOfActivityReached, onNavigateAwayFromActivityRequest } = eventHandlers;
    useEffect(() => {
        const messageListener = (messageEvent) => {
            if (typeof messageEvent.data === 'object') {
                const data = messageEvent.data;
                const event = data.event;
                if (event === 'pr:amplitude initialization details request') {
                    sendAmplitudeInitializationDetailsResponse(iframeRef, sessionUser, rawAnalyticsService, portalArea);
                }
                else if (event === 'pr:user info request') {
                    sendUserInfoToIframe(iframeRef, sessionUser);
                }
                else if (event === 'pr:finished' && typeof data.status === 'string') {
                    onEndOfActivityReached === null || onEndOfActivityReached === void 0 ? void 0 : onEndOfActivityReached(data.status === 'passed');
                }
                else if (event === 'pr:continue' && typeof data.status === 'string') {
                    onNavigateAwayFromActivityRequest(data.status === 'passed');
                }
            }
        };
        window.addEventListener('message', messageListener);
        return () => window.removeEventListener('message', messageListener);
    }, [onEndOfActivityReached, onNavigateAwayFromActivityRequest, portalArea, rawAnalyticsService, sessionUser]); // eslint-disable-line react-hooks/exhaustive-deps
};
