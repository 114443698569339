import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { statusTranslations, possibleActivityTypeOptions } from './FilterLogic';
import { isVideo } from './ContentItem';
import { Badge, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { BetaIcon } from './icons/BetaIcon';
const actionTranslations = {
    in_progress: 'pages.explore.actions.inprogress',
    completed: 'pages.explore.actions.completed',
    not_started: 'pages.explore.actions.notstarted',
};
const IconMap = {
    not_started: PlayArrowRounded,
    in_progress: PauseRounded,
    completed: CheckRounded,
};
export const contentCardPropsFromContentItem = (item) => ({
    id: item.contentId,
    title: item.title,
    description: item.description,
    language: isVideo(item) ? undefined : item.language,
    type: item.type,
    category: item.category,
    status: item.status,
});
export const ContentCard = (props) => (
// eslint-disable-next-line jsx-a11y/no-static-element-interactions
_jsx("div", { className: `card-container ${possibleActivityTypeOptions[props.type].beta ? 'beta' : ''}`, onClick: () => {
        var _a;
        const selection = (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString();
        if (selection)
            return;
        props.onClick();
    }, onKeyUp: (e) => {
        if (e.key === 'Enter' || e.key === 'Space')
            props.onClick();
    }, children: _jsx(ContentCardContent, Object.assign({}, props)) }));
const ContentCardContent = memo(function ContentCardContent(props) {
    const { t } = useTranslation();
    const StatusIcon = IconMap[props.status];
    const activity = possibleActivityTypeOptions[props.type];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "card-info", children: [_jsx("div", { className: "activity-type-info", children: _jsxs("span", { className: "text-with-icon", children: [activity.icon, t(activity.label)] }) }), _jsx("div", { className: "play-button", children: _jsx(Tooltip, { title: t(statusTranslations[props.status]), arrow: true, placement: "bottom", children: _jsx("div", { role: "img", className: `play-circle ${props.status}`, children: _jsx(StatusIcon, {}) }) }) })] }), _jsxs("div", { className: "content", children: [_jsxs("div", { className: "content-text", children: [_jsx("h3", { className: "card-title", children: _jsx("div", { role: "button", tabIndex: 0, onKeyUp: (e) => {
                                        if (e.key === 'Enter' || e.key === 'Space')
                                            props.onClick();
                                    }, "aria-label": t(actionTranslations[props.status], {
                                        title: props.title,
                                        type: t(possibleActivityTypeOptions[props.type].label),
                                    }), children: props.title }) }), props.description && _jsx("p", { children: props.description })] }), _jsxs("div", { className: "badges", children: [activity.beta && (_jsxs(Badge, { color: "blue", children: [_jsx(BetaIcon, {}), t('pages.explore.beta')] })), props.language && (_jsx("span", { className: "cl-badge default", children: _jsxs("div", { className: "text-with-icon", children: [_jsx("i", { className: props.language.iconClassName }), _jsx("span", { children: props.language.label })] }) })), _jsx("span", { className: "cl-badge default", children: props.category.name })] })] })] }));
});
