import { jsx as _jsx } from "react/jsx-runtime";
import { myQuestsCourseActivityAnalytics } from './myQuests.analytics';
import { usePlatformContext } from '../../platformContext';
import { useApiClient } from '../../ApiContext';
import { useParams } from 'react-router-dom';
import { CourseActivity } from '../courses/activities/CourseActivity';
export const MyQuestsActivityFromRouteParams = () => {
    const { courseId, topicCategory, activityType, activityNr: uncheckedActivitynr } = useParams();
    const { trpc } = useApiClient();
    const activityNr = parseInt(uncheckedActivitynr !== null && uncheckedActivitynr !== void 0 ? uncheckedActivitynr : '');
    if (!courseId ||
        !topicCategory ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'pullrequest' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = myQuestsCourseActivityAnalytics(logAnalyticsEvent, sessionUser, 'myQuests');
    const startOrContinueFn = (redoIfCompleted) => {
        const input = { courseId, topicCategory, activityType, activityNr, redoIfCompleted };
        return trpc.myquests.startOrContinueActivity.mutate(input);
    };
    const getNextActivityFrontendPath = (n) => {
        return activityPath(courseId, n.category, n.activityType, n.activityNr);
    };
    const backNavigationData = {
        highlightedActivity: { courseLanguage: courseId, topicCategory, activityType, activityNr },
    };
    return (_jsx(CourseActivity, { courseId: courseId, activityType: activityType, analytics: analytics, analyticsScope: "myQuests", returnPath: "/my-quests", backNavigationData: backNavigationData, startOrContinueFn: startOrContinueFn, getNextActivityFrontendPath: getNextActivityFrontendPath }));
};
export const activityPath = (courseId, category, activityType, activityNr) => {
    return `/my-quests/course/${courseId}/topic/${category}/${activityType}/${activityNr}`;
};
