import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Box, Stack, inputAdornmentClasses } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { StatusIcon } from '../../../common/icons/StatusIcon';
import { useState, useMemo } from 'react';
import { OptionSelect } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../../../platformContext';
import { getCategoryName, getLanguageInformation } from '../../../utils/mapper';
import { Divider } from '../../../Divider';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { myQuestsHomepageAnalytics } from '../myQuests.analytics';
import { ActivityRow } from '../../components/ActivityRow';
const makeTopicActivitiesState = (props, course, metadata) => {
    const backupLanguage = course.backupLanguage ? getLanguageInformation(course.backupLanguage, metadata) : undefined;
    const activityNrs = { video: 1, guideline: 1, challenge: 1, coding_lab: 1, walkthrough: 1, mission: 1, pullrequest: 1 }; // prettier-ignore
    const activityRow = (activity, kind) => {
        var _a, _b, _c, _d;
        const activityNr = activityNrs[activity.kind]++;
        return (_jsx(ActivityRow, { activity: activity, backupLanguage: backupLanguage, kind: kind, onClick: () => props.onActivityClicked(activity, course.courseIdentifier, props.kind === 'practical' ? props.category : props.stableTopicId, activityNr), highlighted: course.courseIdentifier === ((_a = props.highlightedActivity) === null || _a === void 0 ? void 0 : _a.courseLanguage) &&
                (props.kind === 'practical' ? props.category : props.stableTopicId) ===
                    ((_b = props.highlightedActivity) === null || _b === void 0 ? void 0 : _b.topicCategory) &&
                activity.kind === ((_c = props.highlightedActivity) === null || _c === void 0 ? void 0 : _c.activityType) &&
                activityNr === ((_d = props.highlightedActivity) === null || _d === void 0 ? void 0 : _d.activityNr), scrollIfHighlighted: !!props.activityToScrollInView.current, onScrolled: () => (props.activityToScrollInView.current = undefined) }, activity.id));
    };
    const sortedCourseLanguages = props.activitiesByCourse.map((course) => course.courseIdentifier).sort();
    return {
        trainingActivityRows: course.activities.training.map((a) => activityRow(a, 'activities')),
        validationActivityRows: course.activities.validation.map((a) => activityRow(a, 'quiz')),
        selectedCourse: course,
        availableCourses: Object.fromEntries(sortedCourseLanguages.map((lang) => ([lang, getLanguageInformation(lang, metadata).label]))), // prettier-ignore
        props,
    };
};
export const TopicCard = (props) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent, metadata, portalFrontendTranslation } = usePlatformContext();
    const analytics = useMemo(() => myQuestsHomepageAnalytics(logAnalyticsEvent, props.analyticsScope), [logAnalyticsEvent, props.analyticsScope]);
    const [state, setState] = useState(() => {
        if (!props.completed) {
            const lastPlayedCourse = props.activitiesByCourse.find((course) => { var _a; return course.courseIdentifier === ((_a = props.activityToScrollInView.current) === null || _a === void 0 ? void 0 : _a.courseLanguage); });
            if (lastPlayedCourse) {
                return makeTopicActivitiesState(props, lastPlayedCourse, metadata);
            }
            const selectedCourse = props.activitiesByCourse.find((course) => course.courseIdentifier === props.selectedCourseIdentifier);
            if (selectedCourse) {
                return makeTopicActivitiesState(props, selectedCourse, metadata);
            }
        }
        return null;
    });
    return (_jsxs(Box, { bgcolor: (theme) => (props.completed ? theme.palette.container.fill.body : theme.palette.container.fill.card1), sx: (theme) => topicCardContainerSx(theme), children: [_jsx(Stack, { direction: "row", alignItems: "center", children: _jsxs(Stack, { sx: (theme) => ({
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        flexGrow: 1,
                        minHeight: theme.typography.pxToRem(72),
                        py: 4,
                        px: 7,
                        gap: 2,
                    }), children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, children: [props.completed && (_jsx(Stack, { width: (theme) => theme.typography.pxToRem(46), alignItems: "center", children: _jsx(StatusIcon, { status: "passed" }) })), _jsx(Typography, { variant: "h6", component: "h3", children: props.kind !== 'conceptual'
                                        ? getCategoryName(props.category, metadata)
                                        : portalFrontendTranslation(props.translationKey) })] }), props.showRelatedQuests && (_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "center", gap: 2, children: [_jsx(Typography, { variant: "caption", children: t('pages.myQuests.topicCard.relatedQuests', { count: props.relatedQuests.length }) }), _jsx(Stack, { direction: "row", gap: 1, overflow: "hidden", flexShrink: 0, children: props.knownQuests
                                        .filter((q) => props.relatedQuests.includes(q.id))
                                        .map((q, index) => (_jsx(Box, { component: "img", src: q.imageUrl, alt: q.name, sx: (theme) => ({
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            // this logic is temp until design figures out how they want this
                                            // prevents the UI from breaking for now
                                            display: { xs: index > 2 ? 'none' : 'block', md: 'block' },
                                            width: 40,
                                            height: 40,
                                            borderRadius: theme.shape.border.radius.sm,
                                        }) }, `${q.name}-${index}`))) })] }))] }) }), state !== null && (_jsxs(Stack, { gap: 5, px: 7, pb: 7, children: [props.kind !== 'conceptual' && (_jsx(Box, { sx: {
                            maxWidth: '350px',
                            [`.${inputAdornmentClasses.root}`]: {
                                display: 'none',
                            },
                        }, children: _jsx(OptionSelect, { label: "", value: state.selectedCourse.courseIdentifier, onChange: (newLanguage) => {
                                const newCourse = state.props.activitiesByCourse.find((crs) => crs.courseIdentifier === newLanguage);
                                if (newCourse) {
                                    setState(makeTopicActivitiesState(state.props, newCourse, metadata));
                                    analytics.onTopicLanguageChanged(props.category, state.selectedCourse.courseIdentifier, newLanguage);
                                }
                            }, options: state.availableCourses }) })), _jsx(Stack, { gap: 2, role: "list", children: state.trainingActivityRows }), state.validationActivityRows.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Typography, { variant: "caption", sx: (theme) => ({
                                    '& > strong': { color: theme.palette.text.soft },
                                    color: theme.palette.text.body,
                                }), children: _jsx(Trans, { i18nKey: "pages.quests.quizDescription" }) }), _jsx(Stack, { gap: 2, role: "list", mt: -1, children: state.validationActivityRows })] }))] }))] }));
};
export const TopicCardSkeleton = ({ opacity }) => (_jsx(Skeleton, { sx: (theme) => (Object.assign(Object.assign({}, topicCardContainerSx(theme)), { height: theme.typography.pxToRem(72), transform: 'none', opacity })) }));
const topicCardContainerSx = (theme) => ({
    borderRadius: theme.shape.border.radius.md,
    border: `1px solid ${theme.palette.container.border.default}`,
    my: 5,
    '&:first-of-type': {
        marginTop: 10,
    },
    '&:last-of-type': {
        marginBottom: 10,
    },
});
