import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QuizRounded as ValidationIcon, TimelapseRounded as TimelapseIcon } from '@mui/icons-material';
import { Box, ButtonBase, Stack, styled, Typography } from '@mui/material';
import * as ActivityConfig from './activityConfig';
import { formatDateLongMonth, getDaysLeft } from '../../dateHelper';
import { StatusIcon } from '../../common/icons/StatusIcon';
import { VideoIcon } from '../../explore/icons/VideoIcon';
import { ChallengeIcon } from '../../explore/icons/ChallengeIcon';
import { CodingLabIcon } from '../../explore/icons/CodingLabIcon';
import { GuidelineIcon } from '../../explore/icons/GuidelineIcon';
import { MissionIcon } from '../../explore/icons/MissionIcon';
import { getCategoryName } from '../../utils/mapper';
import { visuallyHidden } from '@mui/utils';
import { Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../../platformContext';
import { PullRequestIcon } from '../../explore/icons/PullRequestIcon';
const calculateRemainingCooldownInMinutes = (a) => a.cooldownDate ? Math.max(Math.ceil((a.cooldownDate.getTime() - new Date().getTime()) / 1000 / 60), 0) : 0;
const iconMap = {
    video: VideoIcon,
    challenge: ChallengeIcon,
    coding_lab: CodingLabIcon,
    guideline: GuidelineIcon,
    mission: MissionIcon,
    walkthrough: MissionIcon,
    validation: ValidationIcon,
    pullrequest: PullRequestIcon,
};
const ActivityIcon = ({ kind }) => {
    const Icon = iconMap[kind];
    return (_jsx(Icon, { fontSize: "large", sx: (theme) => ({
            color: theme.palette.container.border.active,
        }) }));
};
const BackupLanguageLabel = ({ language }) => {
    const { t } = useTranslation();
    return (_jsx(Tooltip, { arrow: true, title: t('pages.quests.backupLanguageUsed', { language: language.label }), placement: "top", children: _jsxs(Typography, { variant: "caption", display: "flex", flexDirection: "row", gap: 1.5, alignItems: "center", sx: (theme) => ({
                px: 2,
                py: 1,
                border: `1px solid ${theme.palette.badge.default.border}`,
                backgroundColor: theme.palette.badge.default.fill,
                borderRadius: theme.shape.border.radius.sm,
                color: theme.palette.badge.text,
                [theme.breakpoints.down('sm')]: {
                    '.activityRowLabel': {
                        display: 'none', // could also use `visuallyHidden` here but it gets ignored anyway due to aria-label
                    },
                },
            }), children: [_jsx(Box, { className: language.iconClassName, component: "i", fontSize: (theme) => theme.typography.pxToRem(18) }), _jsx("span", { className: "activityRowLabel", children: language.label })] }) }));
};
const ActivityInfo = styled(Typography)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.caption), { display: 'flex', alignItems: 'center', gap: theme.spacing(0.5), [theme.breakpoints.down('sm')]: {
        '.activityRowLabel': visuallyHidden,
    } })));
export const ActivityRow = (props) => {
    const { activity, highlighted, scrollIfHighlighted, onScrolled, onClick, kind, backupLanguage } = props;
    const { t } = useTranslation();
    const { metadata } = usePlatformContext();
    const [cooldownInMinutes, setCooldownInMinutes] = useState(calculateRemainingCooldownInMinutes(activity));
    const status = activity.finished
        ? 'passed'
        : cooldownInMinutes
            ? 'failed'
            : activity.in_progress
                ? 'in_progress'
                : 'not_started';
    useEffect(() => {
        if (cooldownInMinutes === 0) {
            return;
        }
        const interval = setInterval(() => {
            const newCooldown = calculateRemainingCooldownInMinutes(activity);
            setCooldownInMinutes(newCooldown); // this'll only force a rerender if the value changed
            if (newCooldown === 0) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
        // I didn't add cooldownInMinutes as a dependency to not constantly restart the timer whenever cooldownInMinutes is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);
    const ref = useRef(null);
    useEffect(() => {
        if (scrollIfHighlighted !== false && highlighted && ref.current) {
            const element = ref.current.getBoundingClientRect();
            if (!(element.top < window.innerHeight - element.height && element.bottom >= 0)) {
                // scroll if element is not cmpletely in view
                ref.current.scrollIntoView();
                onScrolled === null || onScrolled === void 0 ? void 0 : onScrolled();
            }
        }
    }, [highlighted, ref]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx("div", { style: { scrollMarginTop: '40px' }, role: "listitem", ref: ref, children: _jsx(ButtonBase, { onClick: onClick, sx: (theme) => ({
                display: 'block',
                width: '100%',
                borderRadius: 3,
                cursor: 'pointer',
                border: `1px solid ${highlighted ? theme.palette.container.border.active : theme.palette.container.border.default}`,
                ':hover': {
                    border: `1px solid ${theme.palette.container.border.input}`,
                },
                ':focus-visible': {
                    outline: `2px solid ${theme.palette.clickable.default}`,
                },
            }), children: _jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", height: (theme) => theme.typography.pxToRem(50), borderRadius: (theme) => `${theme.shape.borderRadius * 3}px 0 0 ${theme.shape.borderRadius * 3}px`, flexGrow: 1, px: 5, py: 3, bgcolor: (theme) => theme.palette.container.fill[activity.finished ? 'card1' : 'card2'], children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, flexGrow: 1, mr: 2, children: [_jsx(ActivityIcon, { kind: kind === 'quiz' ? 'validation' : activity.kind }), _jsx(Typography, { sx: (theme) => ({
                                            textAlign: 'left',
                                            color: theme.palette.text.title,
                                            [theme.breakpoints.down('sm')]: {
                                                flexGrow: 1,
                                            },
                                        }), children: kind === 'quiz'
                                            ? t('pages.quests.quiz')
                                            : 'category' in activity
                                                ? getCategoryName(activity.category, metadata)
                                                : t(ActivityConfig.label[activity.kind]) }), backupLanguage && 'from_backup_language' in activity && activity.from_backup_language && (_jsx(BackupLanguageLabel, { language: backupLanguage }))] }), activity.finished && activity.refresherDate && (_jsx(Tooltip, { arrow: true, placement: "top", title: _jsxs(Stack, { direction: "column", alignItems: "center", children: [_jsx("span", { children: t('pages.quests.expiresOn') }), _jsx(Typography, { fontWeight: "700", children: formatDateLongMonth(activity.refresherDate) })] }), children: _jsxs(ActivityInfo, { "aria-label": t('pages.quests.expiresOn') + ' ' + formatDateLongMonth(activity.refresherDate), children: [_jsx(TimelapseIcon, { sx: (theme) => ({
                                                color: theme.palette.clickable.nav.item.default,
                                                alignSelf: 'center',
                                                fontSize: theme.typography.pxToRem(18),
                                            }) }), _jsx(Trans, { i18nKey: "common.datetime.days", values: { count: getDaysLeft(activity.refresherDate) }, parent: "span", className: "activityRowLabel" })] }) })), !activity.finished &&
                                (cooldownInMinutes ? (_jsx(Tooltip, { arrow: true, placement: "top", title: _jsx(Stack, { direction: "column", alignItems: "center", children: _jsx(Trans, { i18nKey: "pages.quests.retryIn", values: { count: cooldownInMinutes } }) }), children: _jsxs(ActivityInfo, { sx: (theme) => ({
                                            color: theme.palette.text.status.error,
                                        }), children: [_jsx(TimelapseIcon, { sx: (theme) => ({
                                                    fontSize: theme.typography.pxToRem(18),
                                                }) }), _jsx(Trans, { i18nKey: "pages.quests.retryIn", values: { count: cooldownInMinutes }, className: "activityRowLabel", parent: "span" })] }) })) : (_jsx(ActivityInfo, { children: t('common.datetime.mins', { minutes: ActivityConfig.estimatedDuration[activity.kind] }) })))] }), _jsx(Stack, { gap: 3, justifyContent: "flex-end", height: (theme) => theme.typography.pxToRem(50), direction: "row", alignItems: "center", borderRadius: (theme) => `0 ${theme.shape.borderRadius * 3}px ${theme.shape.borderRadius * 3}px 0`, p: 5, bgcolor: (theme) => theme.palette.container.fill[activity.finished ? 'card1' : 'card2'], borderLeft: (theme) => `1px solid ${theme.palette.container.border.default}`, children: _jsx(StatusIcon, { status: status }) })] }) }) }));
};
